import { MapIcon } from '@garmin-avcloud/avc-maps-display';
import { FlightIcons, Icon } from "@garmin-avcloud/avcloud-ui-common/icon";
import { RouteComputedLegLocationType, RouteComputedLegNavaidType } from "@generated/flight-route-service";
import { RecentWaypoint } from "@shared/services/recent-waypoints/recent-waypoints.constants";

export const ICON_MAP = new Map<RouteComputedLegLocationType, Icon>([
  [RouteComputedLegLocationType.AIRWAY, FlightIcons.Navaids.AIRWAY],
  [RouteComputedLegLocationType.INTERSECTION, FlightIcons.Navaids.WPT],
  [RouteComputedLegLocationType.NDB, FlightIcons.Navaids.NDB],
  [RouteComputedLegLocationType.VOR, FlightIcons.Navaids.VOR],
  [RouteComputedLegLocationType.AIRPORT, FlightIcons.Airports.ToweredServices],
  [RouteComputedLegLocationType.ARRIVAL_PROCEDURE, FlightIcons.Navaids.ARRIVAL_PROCEDURE],
  [RouteComputedLegLocationType.DEPARTURE_PROCEDURE, FlightIcons.Navaids.DEPARTURE_PROCEDURE]
]);

export const WAYPOINT_ICON_MAP = new Map<RouteComputedLegLocationType, MapIcon>([
  [RouteComputedLegLocationType.AIRWAY, MapIcon.Airway_Marker],
  [RouteComputedLegLocationType.INTERSECTION, MapIcon.Navaid_WPT],
  [RouteComputedLegLocationType.NDB, MapIcon.Navaid_NDB],
  [RouteComputedLegLocationType.VOR, MapIcon.Navaid_VOR],
  [RouteComputedLegLocationType.AIRPORT, MapIcon.Invalid] // add general airport
]);

export const getWaypointIcon = (waypoint: RecentWaypoint): Icon | string => {
  if (waypoint.type === undefined || waypoint.type.length === 0) {
    return FlightIcons.Navaids.WPT;
  } else if (waypoint.type === "AIRPORT" && waypoint.icon != null) {
    if (waypoint.icon.length > 0) {
      return waypoint.icon;
    } else {
      return ICON_MAP.get(RouteComputedLegLocationType.AIRPORT) as Icon;
    }
  } else if (waypoint.type === "VOR") {
    switch (waypoint.navaidType as RouteComputedLegNavaidType) {
      case RouteComputedLegNavaidType.DME_ONLY: return FlightIcons.Navaids.DME;
      case RouteComputedLegNavaidType.TACAN_ONLY: return FlightIcons.Navaids.TACAN;
      case RouteComputedLegNavaidType.VOR_DME: return FlightIcons.Navaids.VOR_DME;
      case RouteComputedLegNavaidType.VOR_TAC: return FlightIcons.Navaids.VORTAC;
      default: return FlightIcons.Navaids.VOR;
    }
  }
  return ICON_MAP.get(waypoint.type as RouteComputedLegLocationType) ?? FlightIcons.Navaids.WPT;
};
