<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<!-- linthtml-disable indent-style -->
<!-- linthtml-disable indent-width -->
<div class="container"
  [class.map]="mapStyling">
  <button
    flyButton
    class="open-search-btn"
    [class.hidden]="showSearch"
    (click)="onOpenSearch()"
    title="Open search"
    aria-label="Open search"
    [options]="{ style: 'map', size: 'map-icononly' }">
    <fly-icon [icon]="Icons.Search"></fly-icon>
  </button>

  <div
    class="search"
    [class.hidden]="!showSearch">
    <form [formGroup]="formGroup"
      (submit)="selectFirstResult()">
      <div class="search-input"
        [class.map]="mapStyling">
        <fly-input formControlName="searchControl"
          [autocomplete]="false"
          (focusin)="showContent()"
          name="search"
          placeholder="Search">
          <fly-icon [icon]="Icons.Search"
            slot="icon-left"></fly-icon>
          @if (currentSearchState !== State.NoSelection) {
            <fly-icon class="cancel-icon"
              [icon]="Icons.Cancel"
              (click)="onCancelSearch()"
              slot="icon-right"></fly-icon>
          }
        </fly-input>
      </div>
    </form>
  </div>

  @if (showSearchContent) {
    <div class="search-content"
      [class.map]="mapStyling"
      flyIgnoreMapClick>
      <fly-tab-group (selectedTabChange)="showTab($event)">
        <fly-tab class="tab"
          tabSize="medium">Results</fly-tab>
        <fly-tab class="tab"
          tabSize="medium">Favorites</fly-tab>
      </fly-tab-group>

      @switch (tabShown) {
        @case (SearchTab.Results) {
          <div class="tab-item results">

            <div class="recent-items-container">
              <span class="tab-content-label">Recent</span>
              @switch (recentlyViewedState) {
                  @case (State.Loading) {
                    <div class="recent-waypoints-container">Loading recent data...</div>
                  }

                  @case (State.Loaded) {
                    <div #recents
                      class="recent-waypoints-container">
                      @for (wpt of storedWaypoints; track $index) {
                        <button
                          flyButton
                          [options]="{ style: 'pill' }"
                          (click)="onSelectRecentItem(wpt)">
                          @if (wpt.type === 'AIRPORT' && wpt.icon) {
                            <div class="airport-icon">
                              <img alt="icon"
                                src="data:image/svg+xml;base64, {{wpt.icon}}">
                            </div>
                          } @else {
                            <!-- Non-airport icon or generic airport (ToweredServices) icon -->
                            <fly-icon [icon]="getWaypointIcon(wpt)"
                              [ngClass]="'icon-' + wpt.type"></fly-icon>
                          }
                          {{ wpt.identifier }}
                        </button>
                      } @empty {
                        Select a waypoint to show recent items.
                      }
                    </div>
                  }
              }
            </div>

          @switch (currentSearchState) {
            @case (State.Loaded) {
              <div class="loaded-results">
                @for (result of results(); track $index) {
                  @if (result.airport) {
                    <pilot-airport-search-card
                      (selected)="onSelectAirport($event.airport)"
                      [airport]="result.airport">
                    </pilot-airport-search-card>
                  } @else if (result.waypoint) {
                    <pilot-search-card
                    (selected)="onSelectWaypoint($event.waypoint)"
                    [waypoint]="result.waypoint">
                  </pilot-search-card>
                  }
                }
              </div>
            }

            @case (State.Loading) {
              <fly-loading-spinner message="Retrieving data...">
              </fly-loading-spinner>
            }

            @case (State.NoDataAvailable) {
              <div>No results found.</div>
            }

            @case (State.NoSelection) {
              <div class="explanation">
                <span class="body-1-semibold">Search for things like...</span>
                <ul>
                  <li>
                    <span class="explain-left body-2-semibold">KFCM</span>
                    <span class="explain-right body-2"> - Find waypoints by identifier</span>
                  </li>
                  <li>
                    <span class="explain-left body-2-semibold">Minneapolis</span>
                    <span class="explain-right body-2"> - Find by city name</span>
                  </li>
                  <li>
                    <span class="body-explain-left body-2-semibold">Flying Cloud</span>
                    <span class="explain-right body-2"> - Find by facility name</span>
                  </li>
                </ul>
              </div>
            }
          }
          </div>
        }
        @case (SearchTab.Favorites) {
          <div class="tab-item">
            <span class="tab-content-label">Saved</span>
            @if (isAuthenticated()) {
              <div class="loaded-results">
                @for (airport of favoriteAirports; track airport.icao) {
                  <pilot-airport-search-card
                    (selected)="onSelectAirport($event.airport)"
                    [airport]="airport">
                  </pilot-airport-search-card>
                }

                @for (waypoint of favoriteWaypoints; track trackWaypoints(waypoint)) {
                  <pilot-search-card
                    (selected)="onSelectWaypoint($event.waypoint)"
                    [waypoint]="waypoint">
                  </pilot-search-card>
                }
              </div>
            } @else {
              <p>Log in to see your saved waypoints.</p>
            }
          </div>
        }
      }
    </div>
  }
</div>
