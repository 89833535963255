import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { EventEmitter, Component, Input, Output, NgModule } from '@angular/core';
import * as i2 from '@garmin-avcloud/avcloud-ui-common/icon';
import { Icons, FlyIconModule } from '@garmin-avcloud/avcloud-ui-common/icon';
const _c0 = ["*"];
function FlyCardComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 3);
    i0.ɵɵlistener("click", function FlyCardComponent_Conditional_0_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onClick());
    });
    i0.ɵɵelementContainer(1, 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    const content_r3 = i0.ɵɵreference(3);
    i0.ɵɵclassProp("reduced-padding", ctx_r1.padding === "reduced")("selected", ctx_r1.selected);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", content_r3);
  }
}
function FlyCardComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵelementContainer(1, 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    const content_r3 = i0.ɵɵreference(3);
    i0.ɵɵclassProp("reduced-padding", ctx_r1.padding === "reduced")("selected", ctx_r1.selected)("disabled", ctx_r1.disabled);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", content_r3);
  }
}
function FlyCardComponent_ng_template_2_Conditional_1_Case_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "fly-icon", 7);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("icon", ctx_r1.Icons.CautionHardcodedColor);
  }
}
function FlyCardComponent_ng_template_2_Conditional_1_Case_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "fly-icon", 7);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("icon", ctx_r1.Icons.InvalidHardcodedColor);
  }
}
function FlyCardComponent_ng_template_2_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵtemplate(1, FlyCardComponent_ng_template_2_Conditional_1_Case_1_Template, 1, 1, "fly-icon", 7)(2, FlyCardComponent_ng_template_2_Conditional_1_Case_2_Template, 1, 1, "fly-icon", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_3_0;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵconditional((tmp_3_0 = ctx_r1.warning) === "caution" ? 1 : tmp_3_0 === "error" ? 2 : -1);
  }
}
function FlyCardComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0, 0, [1, "content"]);
    i0.ɵɵtemplate(1, FlyCardComponent_ng_template_2_Conditional_1_Template, 3, 1, "div", 6);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r1.warning !== "none" ? 1 : -1);
  }
}
class FlyCardComponent {
  constructor() {
    this.behavior = 'select';
    this.selected = false;
    this.disabled = false;
    this.padding = 'normal';
    this.warning = 'none';
    this.selectedChange = new EventEmitter();
    this.Icons = Icons;
  }
  onClick() {
    switch (this.behavior) {
      case 'toggle':
        this.toggle();
        break;
      case 'select':
        this.select();
        break;
      case 'static':
      default:
        // how did you get here?
        console.error('static card received click event');
        break;
    }
  }
  select() {
    if (!this.selected) {
      this.selected = true;
      this.selectedChange.emit(this.selected);
    }
  }
  toggle() {
    if (this.selected) {
      this.selected = false;
    } else {
      this.selected = true;
    }
    this.selectedChange.emit(this.selected);
  }
  unselect() {
    if (this.selected) {
      this.selected = false;
      this.selectedChange.emit(this.selected);
    }
  }
  static {
    this.ɵfac = function FlyCardComponent_Factory(t) {
      return new (t || FlyCardComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FlyCardComponent,
      selectors: [["fly-card"]],
      inputs: {
        behavior: "behavior",
        selected: "selected",
        disabled: "disabled",
        padding: "padding",
        warning: "warning"
      },
      outputs: {
        selectedChange: "selectedChange"
      },
      ngContentSelectors: _c0,
      decls: 4,
      vars: 1,
      consts: [["content", ""], [1, "card", "selectable", 3, "reduced-padding", "selected"], [1, "card", 3, "reduced-padding", "selected", "disabled"], [1, "card", "selectable", 3, "click"], [3, "ngTemplateOutlet"], [1, "card"], [1, "warning-icon"], [3, "icon"]],
      template: function FlyCardComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, FlyCardComponent_Conditional_0_Template, 2, 5, "button", 1)(1, FlyCardComponent_Conditional_1_Template, 2, 7, "div", 2)(2, FlyCardComponent_ng_template_2_Template, 2, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          i0.ɵɵconditional(ctx.behavior !== "static" ? 0 : 1);
        }
      },
      dependencies: [i1.NgTemplateOutlet, i2.IconComponent],
      styles: ["[_nghost-%COMP%]{display:flex}.card[_ngcontent-%COMP%]{display:flex;justify-content:space-between;gap:.25rem;width:100%;height:100%;align-items:center;flex-shrink:0;background:var(--interactive-ui);padding:1rem;border-radius:.25rem}.card.reduced-padding[_ngcontent-%COMP%]{padding:.5rem .75rem}.card.selected[_ngcontent-%COMP%]{background:var(--selected-ui)}.card.disabled[_ngcontent-%COMP%]{background-color:var(--ui-section)}.card[_ngcontent-%COMP%]:focus-visible{border:solid .125rem var(--focus)}.selectable[_ngcontent-%COMP%]{cursor:pointer}.selectable[_ngcontent-%COMP%]:hover{background:var(--hover-ui)}.selectable[_ngcontent-%COMP%]:active{background:var(--press-ui)}.warning-icon[_ngcontent-%COMP%]{display:flex;justify-content:center;align-items:center}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyCardComponent, [{
    type: Component,
    args: [{
      selector: 'fly-card',
      template: "<!-- linthtml-configure indent-width=\"false\" -->\n\n<!-- If the card can be selected, make it a button. Otherwise it's just a div. -->\n@if (behavior !== 'static') {\n  <button\n    class=\"card selectable\"\n    [class.reduced-padding]=\"padding === 'reduced'\"\n    [class.selected]=\"selected\"\n    (click)=\"onClick()\">\n    <ng-container [ngTemplateOutlet]=\"content\"></ng-container>\n  </button>\n} @else {\n  <div\n    class=\"card\"\n    [class.reduced-padding]=\"padding === 'reduced'\"\n    [class.selected]=\"selected\"\n    [class.disabled]=\"disabled\">\n    <ng-container [ngTemplateOutlet]=\"content\"></ng-container>\n  </div>\n}\n\n<ng-template #content>\n  <ng-content class=\"content\"></ng-content>\n  @if (warning !== 'none') {\n    <div class=\"warning-icon\">\n      @switch (warning) {\n        @case ('caution') {\n          <fly-icon [icon]=\"Icons.CautionHardcodedColor\"></fly-icon>\n        }\n        @case ('error') {\n          <fly-icon [icon]=\"Icons.InvalidHardcodedColor\"></fly-icon>\n        }\n      }\n    </div>\n  }\n</ng-template>\n",
      styles: [":host{display:flex}.card{display:flex;justify-content:space-between;gap:.25rem;width:100%;height:100%;align-items:center;flex-shrink:0;background:var(--interactive-ui);padding:1rem;border-radius:.25rem}.card.reduced-padding{padding:.5rem .75rem}.card.selected{background:var(--selected-ui)}.card.disabled{background-color:var(--ui-section)}.card:focus-visible{border:solid .125rem var(--focus)}.selectable{cursor:pointer}.selectable:hover{background:var(--hover-ui)}.selectable:active{background:var(--press-ui)}.warning-icon{display:flex;justify-content:center;align-items:center}\n"]
    }]
  }], null, {
    behavior: [{
      type: Input
    }],
    selected: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    padding: [{
      type: Input
    }],
    warning: [{
      type: Input
    }],
    selectedChange: [{
      type: Output
    }]
  });
})();
class FlyCardModule {
  static {
    this.ɵfac = function FlyCardModule_Factory(t) {
      return new (t || FlyCardModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: FlyCardModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, FlyIconModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyCardModule, [{
    type: NgModule,
    args: [{
      declarations: [FlyCardComponent],
      imports: [CommonModule, FlyIconModule],
      exports: [FlyCardComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of avcloud-ui-common/card
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FlyCardComponent, FlyCardModule };
