import { DestroyRef, Injectable, WritableSignal, effect, inject, signal, untracked } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LocalStorageService, isStringNonEmpty } from '@garmin-avcloud/avcloud-web-utils';
import { AirportDataService } from 'src/app/features/airport/shared/services/airport-data.service';
import { AirportService } from '../airport/airport.service';
import { STORAGE_NAME, WAYPOINT_SIZE_LIMIT, StoredWaypoint, RecentWaypoint } from './recent-waypoints.constants';

@Injectable({
  providedIn: 'root'
})
/**
 * This service stores the recently selected waypoints into local storage in a simple array
 * of k-v pairs.
 */
export class RecentWaypointsService {
  private readonly airportDataService = inject(AirportDataService);
  private readonly airportService = inject(AirportService);
  private readonly localStorageService = inject(LocalStorageService);
  readonly recentlyStoredWaypoints: WritableSignal<StoredWaypoint[]> = signal([]);
  private readonly destroyRef = inject(DestroyRef);

  constructor() {
    const recentWaypoints = this.localStorageService.get<StoredWaypoint[]>(STORAGE_NAME);
    if (recentWaypoints != null) {
      this.recentlyStoredWaypoints.set([...recentWaypoints]);
    }

    effect(() => {
      const id = this.airportDataService.airportId();
      if (id != null) {
        untracked(() => {
          const matchingRecent = this.recentlyStoredWaypoints()
            .find((wpt) => wpt.identifier === id && wpt.type === 'AIRPORT');

          if (matchingRecent != null) {
            this.addRecentWaypoint(matchingRecent);
          } else {
            this.airportService.searchAirports(id)
              .pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe((results) => {
                if (results != null) {
                  this.addRecentWaypoint({
                    identifier: id,
                    type: 'AIRPORT',
                    icon: results.airports.find((a) => [a.icao, a.iata, a.naa].includes(id))?.icon
                  });
                }
              });
          }
        });
      }
    });
  }

  addRecentWaypoint(waypoint: RecentWaypoint): void {
    const id = waypoint.identifier;
    if (isStringNonEmpty(id)) {
      let uniqueRecentWaypoints: Set<StoredWaypoint> = new Set(this.recentlyStoredWaypoints());
      uniqueRecentWaypoints.forEach((item) => {
        if (item.identifier === id) {
          uniqueRecentWaypoints.delete(item);
        }
      });
      uniqueRecentWaypoints.add({
        ...waypoint,
        time: Date.now()
      });
      const recentlyViewedWaypoints = Array.from(uniqueRecentWaypoints);
      if (uniqueRecentWaypoints.size > WAYPOINT_SIZE_LIMIT) {
        recentlyViewedWaypoints.shift();
      }
      recentlyViewedWaypoints.sort((a, b) => a.time - b.time);
      uniqueRecentWaypoints = new Set(recentlyViewedWaypoints);
      this.localStorageService.set(STORAGE_NAME, [...uniqueRecentWaypoints]);
      this.recentlyStoredWaypoints.set([...uniqueRecentWaypoints]);
    }
  }

  getMostRecentAirport(): StoredWaypoint | null {
    return this.recentlyStoredWaypoints()?.reverse().find((wpt) => wpt.type === 'AIRPORT') ?? null;
  }

  getRecentAirportId(): string | null {
    return this.getMostRecentAirport()?.identifier ?? null;
  }
}
