/**
 * FlightOrchestratorService
 * flightOrchestratorService
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-ProdExp-Cloud-Connectivity@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FlightV2Data } from './flight-v2-data.model';


/**
 * Flight Object
 */
export interface Flight { 
    /**
     * ID of the entity. Leave null if adding new entity
     */
    id?: string;
    /**
     * The date the entity was deleted. Does not need to be set by the client when deleting.
     */
    deletedAt?: string;
    /**
     * The date the entity was created. Leave null when adding a new entity.
     */
    createdAt?: string;
    /**
     * The date the entity was last updated. Leave null when adding a new entity. Do not change this value on the client-side when updating.
     */
    updatedAt?: string;
    /**
     * The Garmin customer UUID string
     */
    customerGuid?: string;
    /**
     * GP legacy id representing this entity. This maps directly to the id for the associated entity in the GP database.
     */
    legacyEntityId?: number;
    /**
     * GP legacy version for the entity in the GP database that this entity is related to.
     */
    legacyEntityVersion?: number;
    /**
     * The ID of the pilot entity for this flight
     */
    pilotId?: string;
    /**
     * The ID that indicates the pilot that is second in command.
     */
    secondInCommandId?: string;
    /**
     * The UUID of the corresponding FlightPlan entity in FlightPlanFiling
     */
    filedFlightPlanId?: string;
    /**
     * The briefing report provided by the BriefingService. This is a link to a PDF document in an external storage service, such as Amazon S3.
     */
    briefingReport?: string;
    /**
     * Whether or not this flight is locked by an admin for modifications.
     */
    isUnderAdminControl?: boolean;
    /**
     * The time at which the flight plan should be filed with a filing service provider
     */
    timeToFile?: string;
    /**
     * User specified AFTN addresses. Space delimited.
     */
    userAftnAddresses?: string;
    /**
     * Special Status Flight
     */
    specialStatus?: string;
    /**
     * Any plain language remarks required by the appropriate ATS authority or deemed necessary by the pilot-in-command for the provision of air traffic services
     */
    remarks?: string;
    /**
     * Replacement Flight Plan
     */
    repFlightPlan?: string;
    /**
     * Originator Indicator
     */
    orgnIndicator?: string;
    /**
     * Where a flight plans to carry out special activities in an area or over an aerodrome, the STAY indicator may be used in the route between the point of entry of the STAY activity and the point of exit of the STAY activity
     */
    stayinfo1?: string;
    /**
     * See stayinfo1
     */
    stayinfo2?: string;
    /**
     * See stayinfo1
     */
    stayinfo3?: string;
    /**
     * See stayinfo1
     */
    stayinfo4?: string;
    /**
     * See stayinfo1
     */
    stayinfo5?: string;
    /**
     * See stayinfo1
     */
    stayinfo6?: string;
    /**
     * See stayinfo1
     */
    stayinfo7?: string;
    /**
     * See stayinfo1
     */
    stayinfo8?: string;
    /**
     * See stayinfo1
     */
    stayinfo9?: string;
    /**
     * ICAO Flight Type
     */
    icaoFlightType?: FlightIcaoFlightType;
    /**
     * Canadian flight type
     */
    canadianFlightType?: FlightCanadianFlightType;
    /**
     * Flight rules
     */
    flightRules?: FlightFlightRules;
    /**
     * DEPRECATED. The date of the flight
     */
    dateOfFlight?: string;
    /**
     * Number of seconds after ETA in which a search and rescue operation will be issued
     */
    sarTime?: number;
    /**
     * Contact information (name) of persons/company to be notified if SAR action is initiated
     */
    sarNotificationName?: string;
    /**
     * Contact information (phone number) of persons/company to be notified if SAR action is initiated
     */
    sarNotificationPhone?: string;
    /**
     * The contact information for where the pilot will deliver their arrival report, when the pilot will close their flight plan/itinerary with a unit other than the destination unit or by another method than radio.
     */
    arrivalContact?: string;
    /**
     * Radio call sign for the flight
     */
    callSign?: string;
    /**
     * Number of people on board during flight
     */
    peopleOnBoard?: number;
    /**
     * Radio communication, navigation and approach aid equipment and capabilities.
     */
    survivalRadioList?: Array<FlightSurvivalRadioList>;
    /**
     * These are supplemental survival remarks to include in item 19 for filing.
     */
    survivalRemarks?: string;
    /**
     * Jacket types
     */
    jacketTypeList?: Array<FlightJacketTypeList>;
    /**
     * ICAO Aircraft Equipment Code(s)
     */
    aircraftEquipmentList?: Array<string>;
    /**
     * The ICAO aircraft surveillance equipment code(s) entered by the user.
     */
    aircraftSurveillanceEquipmentList?: Array<string>;
    /**
     * The US domestic aircraft equipment code(s)
     */
    usAircraftEquipmentList?: Array<string>;
    /**
     * Whether or not a ballistic parachute is installed on the aircraft. Optional for Canadian flight plan filing.
     */
    aircraftBallisticPara?: boolean;
    /**
     * The landing gear type of the aircraft. Required for Canadian flight plans.
     */
    aircraftLandingGear?: FlightAircraftLandingGear;
    /**
     * Types of survival equipment on board
     */
    survivalEquipmentList?: Array<FlightSurvivalEquipmentList>;
    /**
     * Number of dinghies on board
     */
    dinghyCount?: number;
    /**
     * Total person capacity of all dinghies on board.
     */
    dinghiesCapacity?: number;
    /**
     * Color of the dinghies on board.
     */
    dinghiesColor?: string;
    /**
     * Whether or not the dinghies are covered.
     */
    dinghiesCovered?: boolean;
    /**
     * [Item 18] Performance Based Navigation (PBN) code(s)
     */
    perfBasedNavList?: Array<string>;
    /**
     * [Item 18] Navigation Equipment (NAV) code(s). Space-delimited if there are multiple.
     */
    navEquipment?: string;
    /**
     * [Item 18] Communication Equipment (COM) code(s). Space-delimited if there are multiple.
     */
    commEquipment?: string;
    /**
     * [Item 18] Data Link Capability (DAT) code(s). Space-delimited if there are multiple.
     */
    dataLink?: string;
    /**
     * [Item 18] Surveillance (SUR) code(s). Space-delimited if there are multiple.
     */
    surveillance?: string;
    /**
     * [Item 18] 4-letter SELCAL code of the aircraft to which the flight plan relates
     */
    selcal?: string;
    /**
     * Aircraft Type
     */
    aircraftType?: string;
    /**
     * [Item 18] The unique 24-bit aircraft address of the airframe for this aircraft.
     */
    code?: string;
    /**
     * [Item 18] Aircraft Operator (OPR). A single, free-text value.
     */
    aircraftOperator?: string;
    /**
     * Color and markings of the aircraft. For item 19.
     */
    aircraftColorList?: Array<string>;
    /**
     * Single character containing the Secondary Surveillance Radar (SSR) mode.
     */
    ssrMode?: string;
    /**
     * 4 numeric Secondary Surveillance Radar (SSR) code.
     */
    ssrCode?: string;
    /**
     * The home base of the aircraft.
     */
    aircraftBase?: string;
    /**
     * [Item 18] Aircraft Registration (REG)
     */
    aircraftReg?: string;
    /**
     * [Item 18] Runway Visual Range (RVR)
     */
    runwayVisRange?: string;
    /**
     * [Item 18] Aircraft Performance (PER)
     */
    aircraftPerformance?: string;
    /**
     * Aircraft wake turbulence category
     */
    aircraftWakeTurbulence?: FlightAircraftWakeTurbulence;
    /**
     * The tail number of the aircraft
     */
    aircraftTail: string;
    /**
     * Emergency Locator Transmitter Type
     */
    eltType?: string;
    /**
     * En-Route delay or holding (DLE)
     */
    enrouteDelay?: string;
    /**
     * En-route Alternate Aerodrome (RALT)
     */
    enrouteAlt?: string;
    /**
     * Take-off Alternate (TALT)
     */
    takeoffAlt?: string;
    /**
     * Re-Clearance in Flight (RIF)
     */
    reclearanceInFlt?: string;
    /**
     * GarminPilot import: includes aircraft, slotInfo (ref AVSYNCSERV-119), providedAddresses(ref AVSYNCSERV-119) , additionalAddresses(ref AVSYNCSERV-119), supplementalFplInfo(AVSYNCSERV-124), authorityMessageList (ref AVSYNCSERV-228)
     */
    jsonAttributes?: string;
    /**
     * GarminPilot import: status of the flight plan, max 16 characters, can be anything, but is usually \'Filed\', \'Not Filed\', \'Closed\' or \'Activated\' in relation to a flight plan in fpservices
     */
    flightplanStatus?: string;
    /**
     * GarminPilot import: number of aircraft in a formation, always 1 (due to a software limit in both Orch and GP)
     */
    numberOfAircraft?: number;
    /**
     * SFRA Flight Type (DEPARTURE, ENTRY, TRANSIT, WITHIN)
     */
    sfraFlightType?: FlightSfraFlightType;
    /**
     * GarminPilot import: max 64 characters, ref AVSYNCSERV-52
     */
    loadSheetUuid?: string;
    /**
     * GarminPilot import: ref AVSYNCSERV-111
     */
    flightPlanPurged?: boolean;
    /**
     * GarminPilot import: fpservices flight plan user id, max 64 characters, used for communicating flight plans with fpservices to access credentials
     */
    flightPlanUserid?: string;
    /**
     * GarminPilot import: version of the flight plan, max 32 characters, current known version of the flight plan in fpservices
     */
    flightIdVersion?: string;
    /**
     * GarminPilot import: identifier of the flight plan service provider( https://confluence.garmin.com/display/AVCLOUDSW/API+-+Flight+Plan+Filing+Services#APIFlightPlanFilingServices-ServiceProviders) , max 16 characters, should be one of the identifiers supported by FpServices
     */
    serviceProviderId?: string;
    /**
     *  GarminPilot import: information for the \'other info\' field in the ICAO flight plan form, max 4000 characters, must be formatted according to the ICAO form documentation
     */
    item18IcaoOther?: string;
    /**
     * GarminPilot import: result text from an action sent to FpServices, no length limit, additional information provided by the service provider as a result of a file, amend, cancel, close, etc. action
     */
    receiptText?: string;
    /**
     *  GarminPilot import: max 4096 characters, ref AVSYNCSERV-119
     */
    selectedFplString?: string;
    /**
     * GarminPilot import: ref AVSYNCSERV-227
     */
    receiptTextHistory?: string;
    flightV2Data?: FlightV2Data;
    /**
     * UUID referencing weights and balance settings (in JsonSync database)
     */
    weightsAndBalanceSettingsUuid?: string;
    /**
     * Customer defined Estimated Elapsed Time (EET) value to use instead of the system calculated EET, when not null.
     */
    item18EetOverride?: string;
    /**
     * Customer aircraft type override to use in item 18. This field is typically used when numberOfAircraft is greater than one or aircraftType is Non-ICAO (ZZZZ).
     */
    item18TypOverride?: string;
    /**
     * The departure date time, according to UTC.
     */
    departureDateTimeUtc?: string;
    /**
     * Whether the ATC proposed route (if there is one) was accepted by the user.
     */
    atcProposedRouteAccepted?: boolean;
    /**
     * Whether the PDC route (if there is one) was accepted by the user.
     */
    pdcRouteAccepted?: boolean;
    /**
     * The aircraft UUID, referencing the aircraft object in the Aircraft Domain.
     */
    aircraftUuid?: string;
    /**
     * The integer legacy aircraft ID, sent up by GP
     */
    legacyAircraftId?: string;
    /**
     * Whether the GP client which created this flight supports the routeList field. If FALSE, then that means the client only supports routeString and/or routeJson, and OrchestratorProcessService should not populate the routeList field.
     */
    gpRouteListSupported?: boolean;
    /**
     * Fuel endurance user override in minutes.
     */
    fuelEnduranceOverride?: number;
    /**
     * Whether the ETD was derived from the ETA (meaning that ETA is the user-chosen flight scheduling method).
     */
    etdDerivedFromEta?: boolean;
}
export enum FlightIcaoFlightType {
    GENERAL = 'GENERAL',
    MILITARY = 'MILITARY',
    NON_SCHEDULED = 'NON_SCHEDULED',
    SCHEDULED = 'SCHEDULED',
    OTHER = 'OTHER'
};
export enum FlightCanadianFlightType {
    CVFR = 'CVFR',
    DVFR_FP = 'DVFR_FP',
    DVFR_FI_OR_MILITARY = 'DVFR_FI_OR_MILITARY',
    FLIGHT_ITINERARY_OR_SCHEDULED = 'FLIGHT_ITINERARY_OR_SCHEDULED',
    GENERAL_AVIATION = 'GENERAL_AVIATION',
    NON_SCHEDULED = 'NON_SCHEDULED',
    OTHER = 'OTHER'
};
export enum FlightFlightRules {
    VFR = 'VFR',
    IFR = 'IFR',
    YFR = 'YFR',
    ZFR = 'ZFR',
    SFRA = 'SFRA'
};
export enum FlightSurvivalRadioList {
    UHF = 'UHF',
    VHF = 'VHF',
    ELT = 'ELT'
};
export enum FlightJacketTypeList {
    LIGHT = 'LIGHT',
    FLUORESCENT = 'FLUORESCENT',
    UHF = 'UHF',
    VHF = 'VHF'
};
export enum FlightAircraftLandingGear {
    WHEELS = 'WHEELS',
    SKIS_SKIDS_SEAPLANE = 'SKIS_SKIDS_SEAPLANE',
    FLOATS = 'FLOATS',
    AMPHIBIAN = 'AMPHIBIAN',
    WHEELS_AND_SKIDS = 'WHEELS_AND_SKIDS'
};
export enum FlightSurvivalEquipmentList {
    POLAR = 'POLAR',
    DESERT = 'DESERT',
    MARITIME = 'MARITIME',
    JUNGLE = 'JUNGLE'
};
export enum FlightAircraftWakeTurbulence {
    HEAVY = 'HEAVY',
    MEDIUM = 'MEDIUM',
    LIGHT = 'LIGHT'
};
export enum FlightSfraFlightType {
    DEPARTURE = 'DEPARTURE',
    ENTRY = 'ENTRY',
    TRANSIT = 'TRANSIT',
    WITHIN = 'WITHIN'
};



