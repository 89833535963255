import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, NgModule, inject } from '@angular/core';
import * as i1 from '@angular/common/http';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { pipe, of, map as map$1, forkJoin } from 'rxjs';
import { map, shareReplay, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
const featuresServiceConfigToken = new InjectionToken('FeaturesServiceConfig');
const httpHeaders = new HttpHeaders({
  Accept: 'application/json'
});
class FeaturesService {
  constructor(config, http) {
    this.config = config;
    this.http = http;
    this.cache = null;
    this.url = `${this.config.gatewayUrl}${this.config.serviceApiPath}`;
  }
  /**
   * Reaches out to our feature API to determine if a feature is enabled or not.
   * In the event an HTTP request results in an error, the flag will be assumed `false`.
   * @param feature Name of the feature to check whether or not it's enabled.
   * @returns Observable boolean. `true` if feature is enabled, `false` otherwise.
   */
  isFeatureActive(feature) {
    if (this.cache === null) {
      this.cache = this.getFeatures();
    }
    return this.cache.pipe(map(response => {
      return response?.get(feature) ?? false;
    }));
  }
  getFeatures() {
    const httpParams = new HttpParams().append('features', this.config.features.join(','));
    const httpOptions = {
      headers: httpHeaders,
      params: httpParams
    };
    //Cache the request so we only call it once.
    return this.http.get(this.url, httpOptions).pipe(shareReplay(1), pipe(map(response => {
      const featureMap = new Map();
      response.forEach(featureFlag => {
        featureMap.set(featureFlag.feature, featureFlag.enabled);
      });
      return featureMap;
    })), catchError(err => {
      console.error(err);
      //Reset errored observable so we'll try the request again.
      this.cache = null;
      //Anybody still subscribed to old observable will receive null.
      return of(null);
    }));
  }
  static {
    this.ɵfac = function FeaturesService_Factory(t) {
      return new (t || FeaturesService)(i0.ɵɵinject(featuresServiceConfigToken), i0.ɵɵinject(i1.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: FeaturesService,
      factory: FeaturesService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FeaturesService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [featuresServiceConfigToken]
    }]
  }, {
    type: i1.HttpClient
  }], null);
})();
class FeaturesServiceModule {
  static forRoot(config) {
    return {
      ngModule: FeaturesServiceModule,
      providers: [FeaturesService, {
        provide: featuresServiceConfigToken,
        useValue: config
      }]
    };
  }
  static {
    this.ɵfac = function FeaturesServiceModule_Factory(t) {
      return new (t || FeaturesServiceModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: FeaturesServiceModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FeaturesServiceModule, [{
    type: NgModule
  }], null, null);
})();
const FEATURE_GUARD_CONFIG_TOKEN = new InjectionToken('FeatureGuardConfig');
/**
 * A route guard which uses the featureService to determine whether or not a route can be navigated to.
 *
 * @usageNotes
 * Example:
 * ```typescript
 * [
 *   //Deprecated example:
 *   {
 *     canActivate: [ featureGuard ],
 *     data: {
 *       feature: 'SOME_FEATURE_FLAG',
 *       negateFlag: false,
 *       redirectRoute: 'just/an/example'
 *       //No more options will be supported in the future
 *     }
 *   },
 *
 *   //Single Feature FLag Example:
 *   {
 *     canActivate: [ featureGuard ],
 *     data: {
 *       feature: {
 *         name: 'SOME_FEATURE_FLAG',
 *         negateFlag: false,
 *         ...other options
 *       }
 *     }
 *   },
 *
 *   //Multiple Feature Flag Example:
 *   {
 *     canActivate: [ featureGuard ],
 *     data: {
 *       features: [
 *         {
 *           name: 'SOME_FEATURE_FLAG_1',
 *           negateFlag: false,
 *           ...other options
 *         },
 *         'SOME_FEATURE_FLAG_2', //Simply providing a featureFlag name works too
 *         {
 *           name: 'SOME_FEATURE_FLAG_3',
 *           negateFlag: true,
 *           redirectRoute: 'some/alternative/route'
 *         }
 *       ]
 *     }
 *   }
 * ]
 *
 * @param route The current ActivatedRouteSnapshot to determine if the guard should redirect or not.
 * @returns True if the feature is active and the `negateFlag` option is `undefined` or `false`, UrlTree otherwise.
 */
const featureGuard = route => {
  const config = inject(FEATURE_GUARD_CONFIG_TOKEN);
  const featuresService = inject(FeaturesService);
  const router = inject(Router);
  const featureFlag = route.data['feature'];
  if (typeof featureFlag === 'string') {
    //This `if` statement supports the original feature Flag implementation; Non-object and Non-Array;
    const negateFlag = route.data['negateFlag'] ?? false;
    return featuresService.isFeatureActive(featureFlag).pipe(map$1(enabled => {
      return enabled && !negateFlag || negateFlag && !enabled ? true : router.parseUrl(route.data['redirectRoute'] ?? config.redirectRoute);
    }));
  }
  //New Feature flag implementation; either a single object or an array.
  const featureFlagOptions = [];
  if (featureFlag != null && isFeatureFlagsOptions(featureFlag)) {
    featureFlagOptions.push(featureFlag);
  }
  const featureFlags = route.data['features'];
  if (featureFlags != null && Array.isArray(featureFlags)) {
    featureFlags.forEach(flag => {
      if (typeof flag === 'string') {
        featureFlagOptions.push({
          name: flag
        });
      } else if (isFeatureFlagsOptions(flag)) {
        featureFlagOptions.push(flag);
      }
    });
  }
  if (featureFlagOptions.length === 0) {
    throw new Error('No Feature Flag information was provided for featureGuard');
  }
  return forkJoin(featureFlagOptions.map(featureOptions => {
    return featuresService.isFeatureActive(featureOptions.name).pipe(map$1(enabled => {
      const negate = featureOptions.negateFlag ?? false;
      return {
        isFeatureActive: enabled && !negate || negate && !enabled,
        featureOptions
      };
    }));
  })).pipe(map$1(results => {
    const notEnabledFlags = results.find(result => !result.isFeatureActive);
    if (notEnabledFlags != null) {
      return router.parseUrl(notEnabledFlags.featureOptions.redirectRoute ?? config.redirectRoute);
    }
    return true;
  }));
};
const isFeatureFlagsOptions = thing => {
  if (thing == null) {
    return false;
  }
  return thing.name != null;
};

/**
 * Generated bundle index. Do not edit.
 */

export { FEATURE_GUARD_CONFIG_TOKEN, FeaturesService, FeaturesServiceModule, featureGuard, featuresServiceConfigToken };
