import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { inject, DestroyRef, Injector, Component, Input, NgModule } from '@angular/core';
import { NgControl, FormControlName, FormGroupDirective, NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as i2 from '@garmin-avcloud/avcloud-ui-common/button';
import { FlyButtonModule } from '@garmin-avcloud/avcloud-ui-common/button';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
const _c0 = (a0, a1) => ({
  style: a0,
  size: a1
});
function FlySegmentedControllerComponent_ng_container_0_div_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 3);
  }
  if (rf & 2) {
    const i_r2 = i0.ɵɵnextContext().index;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("visible", ctx_r2.activeValueIndex !== i_r2 && ctx_r2.activeValueIndex !== i_r2 + 1)("hidden", ctx_r2.hideDivider);
  }
}
function FlySegmentedControllerComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "button", 1);
    i0.ɵɵlistener("click", function FlySegmentedControllerComponent_ng_container_0_Template_button_click_1_listener() {
      const i_r2 = i0.ɵɵrestoreView(_r1).index;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onClick(i_r2));
    });
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(3, FlySegmentedControllerComponent_ng_container_0_div_3_Template, 1, 4, "div", 2);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const i_r2 = ctx.index;
    const isLast_r4 = ctx.last;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵclassProp("blue", ctx_r2.activeValueIndex === i_r2)("neutral", ctx_r2.activeValueIndex !== i_r2);
    i0.ɵɵproperty("options", i0.ɵɵpureFunction2(8, _c0, ctx_r2.defaultButtonStyle, ctx_r2.defaultButtonSize))("disabled", ctx_r2.disabled);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r2.options[i_r2].label, " ");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !isLast_r4);
  }
}
const DEFAULT_BUTTON_STYLE = 'primary';
const DEFAULT_BUTTON_SIZE = 'medium';
class FlySegmentedControllerComponent {
  constructor() {
    this.options = [];
    this.activeValueIndex = 0;
    this.hideDivider = false;
    this.defaultButtonSize = DEFAULT_BUTTON_SIZE;
    this.defaultButtonStyle = DEFAULT_BUTTON_STYLE;
    this.disabled = false;
    this.touched = false;
    this.onChange = _value => {
      //
    };
    this.onTouched = () => {
      //
    };
    this.destroyRef = inject(DestroyRef);
    this.injector = inject(Injector);
  }
  ngOnInit() {
    // Grab injected form control instance.
    const ngControl = this.injector.get(NgControl);
    if (ngControl instanceof FormControlName) {
      this.controlIndex = this.injector.get(FormGroupDirective).getControl(ngControl);
    } else {
      this.controlIndex = ngControl.form;
    }
    this.controlIndex.setValue(this.activeValueIndex);
  }
  ngAfterViewInit() {
    this.controlIndex.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      this.activeValueIndex = value;
    });
  }
  writeValue(value) {
    this.value = value;
  }
  registerOnChange(onChange) {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched) {
    this.onTouched = onTouched;
  }
  onClick(value) {
    this.controlIndex.markAsTouched();
    if (value != this.controlIndex.value) this.controlIndex.markAsDirty();
    this.controlIndex.setValue(value);
  }
  static {
    this.ɵfac = function FlySegmentedControllerComponent_Factory(t) {
      return new (t || FlySegmentedControllerComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FlySegmentedControllerComponent,
      selectors: [["fly-segmented-controller"]],
      inputs: {
        options: "options",
        activeValueIndex: "activeValueIndex",
        hideDivider: "hideDivider"
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: FlySegmentedControllerComponent
      }])],
      decls: 1,
      vars: 1,
      consts: [[4, "ngFor", "ngForOf"], ["flyButton", "", "type", "button", 1, "dt-button", 3, "click", "options", "disabled"], ["class", "divider", 3, "visible", "hidden", 4, "ngIf"], [1, "divider"]],
      template: function FlySegmentedControllerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, FlySegmentedControllerComponent_ng_container_0_Template, 4, 11, "ng-container", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngForOf", ctx.options);
        }
      },
      dependencies: [i1.NgForOf, i1.NgIf, i2.FlyButtonDirective],
      styles: [".dt-button[_ngcontent-%COMP%]{font-family:roboto;font-weight:600;font-size:.75rem;line-height:1.25rem}[_nghost-%COMP%]{display:flex;flex-direction:row;justify-content:center;align-items:center;background-color:var(--interactive-background);border-radius:.25rem}.dt-button[_ngcontent-%COMP%]{width:2.9375rem;height:1.125rem}.divider[_ngcontent-%COMP%]{width:.0625rem;height:.698rem;background-color:var(--interactive-background);margin-left:.125rem;margin-right:.125rem}.visible[_ngcontent-%COMP%]{background-color:var(--ui-data-entry)}.neutral[_ngcontent-%COMP%]{background-color:var(--interactive-background);color:var(--text-secondary)}.blue[_ngcontent-%COMP%]{border-right:none}.hidden[_ngcontent-%COMP%]{visibility:hidden}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlySegmentedControllerComponent, [{
    type: Component,
    args: [{
      selector: 'fly-segmented-controller',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: FlySegmentedControllerComponent
      }],
      template: "<ng-container *ngFor=\"let option of options; index as i; last as isLast\">\n  <button flyButton\n    class=\"dt-button\"\n    [class.blue]=\"activeValueIndex===i\"\n    [class.neutral]=\"activeValueIndex!==i\"\n    type=\"button\"\n    (click)=\"onClick(i)\"\n    [options]=\"{\n      style: defaultButtonStyle,\n      size: defaultButtonSize\n    }\"\n    [disabled]=\"disabled\">\n    {{options[i].label}}\n  </button>\n  <div\n    *ngIf=\"!isLast\"\n    class=\"divider\"\n    [class.visible]=\"activeValueIndex!==i && activeValueIndex !== i+1\"\n    [class.hidden]=\"hideDivider\">\n  </div>\n</ng-container>\n",
      styles: [".dt-button{font-family:roboto;font-weight:600;font-size:.75rem;line-height:1.25rem}:host{display:flex;flex-direction:row;justify-content:center;align-items:center;background-color:var(--interactive-background);border-radius:.25rem}.dt-button{width:2.9375rem;height:1.125rem}.divider{width:.0625rem;height:.698rem;background-color:var(--interactive-background);margin-left:.125rem;margin-right:.125rem}.visible{background-color:var(--ui-data-entry)}.neutral{background-color:var(--interactive-background);color:var(--text-secondary)}.blue{border-right:none}.hidden{visibility:hidden}\n"]
    }]
  }], null, {
    options: [{
      type: Input
    }],
    activeValueIndex: [{
      type: Input
    }],
    hideDivider: [{
      type: Input
    }]
  });
})();
class FlySegmentedControllerModule {
  static {
    this.ɵfac = function FlySegmentedControllerModule_Factory(t) {
      return new (t || FlySegmentedControllerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: FlySegmentedControllerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, FormsModule, ReactiveFormsModule, FlyButtonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlySegmentedControllerModule, [{
    type: NgModule,
    args: [{
      declarations: [FlySegmentedControllerComponent],
      imports: [CommonModule, FormsModule, ReactiveFormsModule, FlyButtonModule],
      exports: [FlySegmentedControllerComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of avcloud-ui-common/segmented-controller
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FlySegmentedControllerComponent, FlySegmentedControllerModule };
