import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, Input, NgModule } from '@angular/core';
import * as i1 from '@angular/forms';
import { NgControl, FormControlName, FormGroupDirective, NG_VALUE_ACCESSOR, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Identifiable } from '@garmin-avcloud/avcloud-ui-common/shared';
function FlyRadioComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "label", 2);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("for", ctx_r0.identifier);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.label);
  }
}
function FlyRadioComponent_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.subtext);
  }
}
class FlyRadioComponent extends Identifiable {
  constructor(injector) {
    super('fly-radio');
    this.injector = injector;
    this.id = null;
    this.name = '';
    this.value = '';
    this.label = '';
    this.subtext = '';
    this.disableValue = false;
    this.onTouched = () => {
      return;
    };
    this.onChanged = () => {
      return;
    };
  }
  ngOnInit() {
    this.identifier = this.id;
    this.id = '';
    super.ngOnInit();
    const ngControl = this.injector.get(NgControl);
    if (ngControl instanceof FormControlName) {
      this.formControl = this.injector.get(FormGroupDirective).getControl(ngControl);
    } else {
      this.formControl = ngControl.form;
    }
  }
  /* eslint-disable @typescript-eslint/no-empty-function */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  writeValue(obj) {}
  /* eslint-enable @typescript-eslint/no-empty-function */
  /* eslint-enable @typescript-eslint/no-unused-vars */
  registerOnChange(fn) {
    this.onChanged = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  static {
    this.ɵfac = function FlyRadioComponent_Factory(t) {
      return new (t || FlyRadioComponent)(i0.ɵɵdirectiveInject(i0.Injector));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FlyRadioComponent,
      selectors: [["fly-radio"]],
      inputs: {
        id: "id",
        name: "name",
        value: "value",
        label: "label",
        subtext: "subtext",
        disableValue: "disableValue"
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: FlyRadioComponent
      }]), i0.ɵɵInheritDefinitionFeature],
      decls: 4,
      vars: 9,
      consts: [[1, "fly-radio", 3, "disabled"], ["type", "radio", 3, "name", "value", "id", "formControl"], [1, "fly-input-label", 3, "for"], [1, "subtext", "body-3"]],
      template: function FlyRadioComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "fieldset", 0);
          i0.ɵɵelement(1, "input", 1);
          i0.ɵɵtemplate(2, FlyRadioComponent_Conditional_2_Template, 2, 2, "label", 2)(3, FlyRadioComponent_Conditional_3_Template, 2, 1, "div", 3);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassProp("fly-input-disabled", ctx.formControl.disabled);
          i0.ɵɵproperty("disabled", ctx.disableValue);
          i0.ɵɵadvance();
          i0.ɵɵproperty("name", ctx.name)("value", ctx.value)("id", ctx.identifier)("formControl", ctx.formControl);
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.label !== "" ? 2 : -1);
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.subtext !== "" ? 3 : -1);
        }
      },
      dependencies: [i1.DefaultValueAccessor, i1.RadioControlValueAccessor, i1.NgControlStatus, i1.FormControlDirective],
      styles: [".fly-radio[_ngcontent-%COMP%]{display:grid;grid-template-columns:1rem auto;gap:.375rem;align-items:center;line-height:0;cursor:pointer}.fly-radio[_ngcontent-%COMP%]:disabled{color:var(--disabled-02);cursor:default}.fly-radio[_ngcontent-%COMP%]   label[_ngcontent-%COMP%]{display:inline-flex;align-items:center;margin-bottom:0}.fly-input-disabled[_ngcontent-%COMP%]   .fly-radio[_ngcontent-%COMP%]   label[_ngcontent-%COMP%]{color:var(--disabled-02)}.fly-radio[_ngcontent-%COMP%]   .subtext[_ngcontent-%COMP%]{color:var(--text-secondary);margin-left:1.375rem;position:relative;top:-.5rem;height:.5rem}.fly-radio[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]{margin:0;appearance:none;background-color:transparent;font:inherit;color:var(--text-primary);width:1.125rem;height:1.125rem;border:.15rem solid var(--focus);border-radius:50%;display:grid;place-content:center}.fly-radio[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]:before{content:\"\";width:.75rem;height:.75rem;border-radius:50%;visibility:hidden;transition:.12s transform ease-in-out;box-shadow:inset .75rem .75rem var(--interactive-primary)}.fly-radio[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]:checked:before{visibility:visible}.fly-radio[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]:checked:disabled:before{box-shadow:inset .75rem .75rem var(--disabled-03)}.fly-radio[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]:checked:hover{border-color:var(--hover-tertiary)}.fly-radio[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]:disabled{pointer-events:none;border-color:var(--disabled-02)}.fly-radio[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]:disabled:hover{border-color:var(--disabled-02)}.fly-radio[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]:hover{border-color:var(--hover-tertiary)}.fly-radio[_ngcontent-%COMP%]   input[_ngcontent-%COMP%]:focus{border:.125rem solid var(--focus);box-shadow:0 0 0 .0625rem var(--ui-background),0 0 0 .1875rem var(--focus)}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyRadioComponent, [{
    type: Component,
    args: [{
      selector: 'fly-radio',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: FlyRadioComponent
      }],
      template: "<fieldset\n  class=\"fly-radio\"\n  [class.fly-input-disabled]=\"formControl.disabled\"\n  [disabled]=\"disableValue\">\n  <!-- linthtml-configure input-req-label=\"false\" -->\n  <!-- linthtml-configure input-radio-req-name=\"false\" -->\n  <input\n    type=\"radio\"\n    [name]=\"name\"\n    [value]=\"value\"\n    [id]=\"identifier\"\n    [formControl]=\"formControl\">\n  <!-- linthtml-configure input-radio-req-name=\"$previous\" -->\n  <!-- linthtml-configure input-req-label=\"$previous\" -->\n\n  <!-- linthtml-configure label-req-for=\"false\" -->\n  @if (label !== '') {\n  <label\n    class=\"fly-input-label\"\n    [for]=\"identifier\">{{label}}</label>\n  }\n  <!-- linthtml-configure label-req-for=\"$previous\" -->\n  @if (subtext !== '') { <div class=\"subtext body-3\">{{ subtext }}</div> }\n</fieldset>\n",
      styles: [".fly-radio{display:grid;grid-template-columns:1rem auto;gap:.375rem;align-items:center;line-height:0;cursor:pointer}.fly-radio:disabled{color:var(--disabled-02);cursor:default}.fly-radio label{display:inline-flex;align-items:center;margin-bottom:0}.fly-input-disabled .fly-radio label{color:var(--disabled-02)}.fly-radio .subtext{color:var(--text-secondary);margin-left:1.375rem;position:relative;top:-.5rem;height:.5rem}.fly-radio input{margin:0;appearance:none;background-color:transparent;font:inherit;color:var(--text-primary);width:1.125rem;height:1.125rem;border:.15rem solid var(--focus);border-radius:50%;display:grid;place-content:center}.fly-radio input:before{content:\"\";width:.75rem;height:.75rem;border-radius:50%;visibility:hidden;transition:.12s transform ease-in-out;box-shadow:inset .75rem .75rem var(--interactive-primary)}.fly-radio input:checked:before{visibility:visible}.fly-radio input:checked:disabled:before{box-shadow:inset .75rem .75rem var(--disabled-03)}.fly-radio input:checked:hover{border-color:var(--hover-tertiary)}.fly-radio input:disabled{pointer-events:none;border-color:var(--disabled-02)}.fly-radio input:disabled:hover{border-color:var(--disabled-02)}.fly-radio input:hover{border-color:var(--hover-tertiary)}.fly-radio input:focus{border:.125rem solid var(--focus);box-shadow:0 0 0 .0625rem var(--ui-background),0 0 0 .1875rem var(--focus)}\n"]
    }]
  }], () => [{
    type: i0.Injector
  }], {
    id: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    subtext: [{
      type: Input
    }],
    disableValue: [{
      type: Input
    }]
  });
})();
class FlyRadioModule {
  static {
    this.ɵfac = function FlyRadioModule_Factory(t) {
      return new (t || FlyRadioModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: FlyRadioModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, FormsModule, ReactiveFormsModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyRadioModule, [{
    type: NgModule,
    args: [{
      declarations: [FlyRadioComponent],
      imports: [CommonModule, FormsModule, ReactiveFormsModule],
      exports: [FlyRadioComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of avcloud-ui-common/radio
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FlyRadioComponent, FlyRadioModule };
