import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, Input, NgModule } from '@angular/core';
import * as i2 from '@garmin-avcloud/avcloud-ui-common/icon';
import { Icons, FlyIconModule } from '@garmin-avcloud/avcloud-ui-common/icon';
import { trigger, state, style, transition, group, animate } from '@angular/animations';
const _c0 = [[["", "header", ""]], "*"];
const _c1 = ["[header]", "*"];
function FlyCollapsibleComponent_p_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.title);
  }
}
function FlyCollapsibleComponent_fly_icon_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "fly-icon", 7);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("icon", ctx_r0.Icons.CautionHardcodedColor);
  }
}
function FlyCollapsibleComponent_fly_icon_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "fly-icon", 7);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("icon", ctx_r0.Icons.InvalidHardcodedColor);
  }
}
function FlyCollapsibleComponent_div_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵprojection(1, 1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("expanded", ctx_r0.expanded);
    i0.ɵɵproperty("@collapseExpand", ctx_r0.animationState);
  }
}
const CollapseExpandAnimation = [trigger('collapseExpand', [state('expand', style({
  height: '100%',
  opacity: '1',
  visibility: 'visible'
})), state('collapse', style({
  height: '0',
  opacity: '0',
  visibility: 'hidden',
  overflow: 'hidden'
})), transition('expand => collapse', [group([animate('100ms linear', style({
  opacity: '0',
  height: '0',
  visibility: 'hidden',
  overflow: 'hidden'
}))])]), transition('collapse => expand', [group([animate('1ms linear', style({
  visibility: 'visible'
})), animate('100ms linear', style({
  height: '100%',
  opacity: '1'
}))])])])];

/**
 * Fly-Collapsible - Content agnostic collapsible container
 *
 * @example Basic use with title parameter
 * <fly-collapsible
 *   title="Weather Information">
 *   <h3>METARs</h3>
 *   ...
 * </fly-collapsible>
 *
 * @example Using header slot for more complex content in header
 * <fly-collapsible
 *   [error]="true">
 *   <div header>
 *     <dynamic-content-container></dynamic-content-container>
 *     <fly-icon [icon]="Icons.whatever"></fly-icon>
 *     ...
 *   </div>
 *   <div>
 *     <h3>METARs</h3>
 *     <p>METARs data is not available currently</p>
 *   </div>
 * </fly-collapsible>
 *
 * @example Using hideOnly flag so that content is (loaded & not displayed) on page load
 * <fly-collapsible
 *   title="Big Data Stuff"
 *   [hideOnly]="true">
 *   <data-intensive-component></data-intensive-component>
 *   ...
 * </fly-collapsible>
 */
class FlyCollapsibleComponent {
  constructor() {
    this.expanded = false;
    this.disabled = false;
    this.hideOnly = false;
    this.warning = 'none';
    this.Icons = Icons;
    this.animationState = 'collapse';
  }
  onClickHeader() {
    this.expanded = !this.hideOnly ? !this.expanded : false;
    this.animationState = this.expanded ? 'expand' : 'collapse';
  }
  static {
    this.ɵfac = function FlyCollapsibleComponent_Factory(t) {
      return new (t || FlyCollapsibleComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FlyCollapsibleComponent,
      selectors: [["fly-collapsible"]],
      inputs: {
        title: "title",
        expanded: "expanded",
        disabled: "disabled",
        hideOnly: "hideOnly",
        warning: "warning"
      },
      ngContentSelectors: _c1,
      decls: 10,
      vars: 10,
      consts: [[1, "fly-collapsible-container"], [1, "header", 3, "click"], [1, "expanded-icon", 3, "icon"], [4, "ngIf"], [1, "warning-icon", 3, "ngSwitch"], [3, "icon", 4, "ngSwitchCase"], ["class", "content", 3, "expanded", 4, "ngIf"], [3, "icon"], [1, "content"]],
      template: function FlyCollapsibleComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "div", 0)(1, "div", 1);
          i0.ɵɵlistener("click", function FlyCollapsibleComponent_Template_div_click_1_listener() {
            return ctx.onClickHeader();
          });
          i0.ɵɵelement(2, "fly-icon", 2);
          i0.ɵɵelementStart(3, "div");
          i0.ɵɵtemplate(4, FlyCollapsibleComponent_p_4_Template, 2, 1, "p", 3);
          i0.ɵɵprojection(5);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(6, "div", 4);
          i0.ɵɵtemplate(7, FlyCollapsibleComponent_fly_icon_7_Template, 1, 1, "fly-icon", 5)(8, FlyCollapsibleComponent_fly_icon_8_Template, 1, 1, "fly-icon", 5);
          i0.ɵɵelementEnd()();
          i0.ɵɵtemplate(9, FlyCollapsibleComponent_div_9_Template, 2, 3, "div", 6);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵclassProp("disabled", ctx.disabled);
          i0.ɵɵadvance();
          i0.ɵɵclassProp("expanded", ctx.expanded);
          i0.ɵɵproperty("icon", ctx.Icons.SelectDropdown);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", ctx.title);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngSwitch", ctx.warning);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngSwitchCase", "caution");
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngSwitchCase", "error");
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", !ctx.disabled);
        }
      },
      dependencies: [i1.NgIf, i1.NgSwitch, i1.NgSwitchCase, i2.IconComponent],
      styles: [".fly-collapsible-container[_ngcontent-%COMP%] > .header[_ngcontent-%COMP%]{background:var(--ui-section);display:flex;padding:.5rem .875rem;gap:.5rem;align-items:center;cursor:pointer;-webkit-user-select:none;user-select:none}.fly-collapsible-container[_ngcontent-%COMP%] > .header[_ngcontent-%COMP%]:hover{background:var(--hover-ui)}.fly-collapsible-container[_ngcontent-%COMP%] > .header[_ngcontent-%COMP%]:focus{border:solid 2px var(--focus)}.fly-collapsible-container[_ngcontent-%COMP%] > .header.disabled[_ngcontent-%COMP%]{background:var(--interactive-ui);pointer-events:none;color:var(--disabled-02)}.fly-collapsible-container[_ngcontent-%COMP%] > .header[_ngcontent-%COMP%] > .expanded-icon[_ngcontent-%COMP%]{transition:transform .1s;transform:rotate(-90deg)}.fly-collapsible-container[_ngcontent-%COMP%] > .header[_ngcontent-%COMP%] > .expanded-icon.expanded[_ngcontent-%COMP%]{transform:rotate(0)}.fly-collapsible-container[_ngcontent-%COMP%] > .header[_ngcontent-%COMP%]   .warning-icon[_ngcontent-%COMP%]{display:flex}.fly-collapsible-container[_ngcontent-%COMP%] > .header[_ngcontent-%COMP%] > .content[_ngcontent-%COMP%]{flex-grow:1}"],
      data: {
        animation: [CollapseExpandAnimation]
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyCollapsibleComponent, [{
    type: Component,
    args: [{
      selector: 'fly-collapsible',
      animations: [CollapseExpandAnimation],
      template: "<div class=\"fly-collapsible-container\">\n  <div\n    class=\"header\"\n    [class.disabled]=\"disabled\"\n    (click)=\"onClickHeader()\">\n    <fly-icon\n      class=\"expanded-icon\"\n      [class.expanded]=\"expanded\"\n      [icon]=\"Icons.SelectDropdown\"></fly-icon>\n    <div>\n      <p *ngIf=\"title\">{{title}}</p>\n      <ng-content select=\"[header]\"></ng-content>\n    </div>\n    <div\n      [ngSwitch]=\"warning\"\n      class=\"warning-icon\">\n      <fly-icon\n        *ngSwitchCase=\"'caution'\"\n        [icon]=\"Icons.CautionHardcodedColor\"></fly-icon>\n      <fly-icon\n        *ngSwitchCase=\"'error'\"\n        [icon]=\"Icons.InvalidHardcodedColor\"></fly-icon>\n    </div>\n  </div>\n  <div\n    *ngIf=\"!disabled\"\n    class=\"content\"\n    [class.expanded]=\"expanded\"\n    [@collapseExpand]=\"animationState\">\n    <ng-content></ng-content>\n  </div>\n</div>\n",
      styles: [".fly-collapsible-container>.header{background:var(--ui-section);display:flex;padding:.5rem .875rem;gap:.5rem;align-items:center;cursor:pointer;-webkit-user-select:none;user-select:none}.fly-collapsible-container>.header:hover{background:var(--hover-ui)}.fly-collapsible-container>.header:focus{border:solid 2px var(--focus)}.fly-collapsible-container>.header.disabled{background:var(--interactive-ui);pointer-events:none;color:var(--disabled-02)}.fly-collapsible-container>.header>.expanded-icon{transition:transform .1s;transform:rotate(-90deg)}.fly-collapsible-container>.header>.expanded-icon.expanded{transform:rotate(0)}.fly-collapsible-container>.header .warning-icon{display:flex}.fly-collapsible-container>.header>.content{flex-grow:1}\n"]
    }]
  }], null, {
    title: [{
      type: Input
    }],
    expanded: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    hideOnly: [{
      type: Input
    }],
    warning: [{
      type: Input
    }]
  });
})();
class FlyCollapsibleModule {
  static {
    this.ɵfac = function FlyCollapsibleModule_Factory(t) {
      return new (t || FlyCollapsibleModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: FlyCollapsibleModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, FlyIconModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyCollapsibleModule, [{
    type: NgModule,
    args: [{
      declarations: [FlyCollapsibleComponent],
      imports: [CommonModule, FlyIconModule],
      exports: [FlyCollapsibleComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of avcloud-ui-common/collapsible
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FlyCollapsibleComponent, FlyCollapsibleModule };
