import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, Input, NgModule } from '@angular/core';
import * as i1$1 from '@garmin-avcloud/avcloud-ui-common/button';
import { FlyButtonModule } from '@garmin-avcloud/avcloud-ui-common/button';
import * as i2 from '@garmin-avcloud/avcloud-ui-common/icon';
import { Icons, FlyIconModule } from '@garmin-avcloud/avcloud-ui-common/icon';
import * as i3 from '@garmin-avcloud/avcloud-ui-common/tooltips';
import { FlyTooltipModule } from '@garmin-avcloud/avcloud-ui-common/tooltips';
function FlyDataComponent_div_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.unit, " ");
  }
}
function FlyDataComponent_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.unit, " ");
  }
}
function FlyDataVerticalComponent_fly_icon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "fly-icon", 3);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("icon", ctx_r0.Icons.Tooltip)("popOutOptions", ctx_r0.tooltipOptions);
  }
}
const _c0 = ["*"];
class FlyDataComponent {
  constructor() {
    this.Icons = Icons;
  }
  static {
    this.ɵfac = function FlyDataComponent_Factory(t) {
      return new (t || FlyDataComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FlyDataComponent,
      selectors: [["fly-data"]],
      inputs: {
        body: "body",
        unit: "unit",
        unit2: "unit2"
      },
      decls: 5,
      vars: 3,
      consts: [[1, "wrapper"], ["class", "uom", 4, "ngIf"], ["class", "uom unit-2", 4, "ngIf"], [1, "uom"], [1, "uom", "unit-2"]],
      template: function FlyDataComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0)(1, "div");
          i0.ɵɵtext(2);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(3, FlyDataComponent_div_3_Template, 2, 1, "div", 1)(4, FlyDataComponent_div_4_Template, 2, 1, "div", 2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate(ctx.body);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.unit);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.unit2);
        }
      },
      dependencies: [i1.NgIf],
      styles: [".wrapper[_ngcontent-%COMP%]{display:flex;align-items:baseline}.unit-2[_ngcontent-%COMP%]{padding-left:.125rem}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyDataComponent, [{
    type: Component,
    args: [{
      selector: 'fly-data',
      template: "<div class=\"wrapper\">\n  <div>{{body}}</div>\n  <div *ngIf=\"unit\"\n        class=\"uom\">\n    {{unit}}\n  </div>\n  <div *ngIf=\"unit2\"\n        class=\"uom unit-2\">\n    {{unit}}\n  </div>\n</div>\n",
      styles: [".wrapper{display:flex;align-items:baseline}.unit-2{padding-left:.125rem}\n"]
    }]
  }], null, {
    body: [{
      type: Input
    }],
    unit: [{
      type: Input
    }],
    unit2: [{
      type: Input
    }]
  });
})();
class FlyDataHorizontalComponent {
  constructor() {
    this.Icons = Icons;
  }
  static {
    this.ɵfac = function FlyDataHorizontalComponent_Factory(t) {
      return new (t || FlyDataHorizontalComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FlyDataHorizontalComponent,
      selectors: [["fly-data-horizontal"]],
      inputs: {
        body: "body",
        title: "title",
        unit: "unit",
        unit2: "unit2"
      },
      decls: 3,
      vars: 4,
      consts: [[1, "title"], [3, "body", "unit", "unit2"]],
      template: function FlyDataHorizontalComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtext(1);
          i0.ɵɵelementEnd();
          i0.ɵɵelement(2, "fly-data", 1);
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate1("", ctx.title, ":");
          i0.ɵɵadvance();
          i0.ɵɵproperty("body", ctx.body)("unit", ctx.unit)("unit2", ctx.unit2);
        }
      },
      dependencies: [FlyDataComponent],
      styles: [".title[_ngcontent-%COMP%]{font-family:roboto;font-weight:400;font-size:.75rem;line-height:1.25rem}[_nghost-%COMP%]{display:flex;gap:.25rem;align-items:baseline}.title[_ngcontent-%COMP%]{display:flex;color:var(--text-secondary)}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyDataHorizontalComponent, [{
    type: Component,
    args: [{
      selector: 'fly-data-horizontal',
      template: "<div class=\"title\">{{title}}:</div>\n<fly-data [body]=\"body\"\n          [unit]=\"unit\"\n          [unit2]=\"unit2\">\n</fly-data>\n",
      styles: [".title{font-family:roboto;font-weight:400;font-size:.75rem;line-height:1.25rem}:host{display:flex;gap:.25rem;align-items:baseline}.title{display:flex;color:var(--text-secondary)}\n"]
    }]
  }], null, {
    body: [{
      type: Input
    }],
    title: [{
      type: Input
    }],
    unit: [{
      type: Input
    }],
    unit2: [{
      type: Input
    }]
  });
})();
class FlyDataVerticalComponent {
  constructor() {
    this.Icons = Icons;
  }
  static {
    this.ɵfac = function FlyDataVerticalComponent_Factory(t) {
      return new (t || FlyDataVerticalComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FlyDataVerticalComponent,
      selectors: [["fly-data-vertical"]],
      inputs: {
        body: "body",
        label: "label",
        tooltipOptions: "tooltipOptions",
        unit: "unit",
        unit2: "unit2"
      },
      decls: 4,
      vars: 5,
      consts: [[1, "label"], ["flyTooltip", "", 3, "icon", "popOutOptions", 4, "ngIf"], [3, "body", "unit", "unit2"], ["flyTooltip", "", 3, "icon", "popOutOptions"]],
      template: function FlyDataVerticalComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtext(1);
          i0.ɵɵtemplate(2, FlyDataVerticalComponent_fly_icon_2_Template, 1, 2, "fly-icon", 1);
          i0.ɵɵelementEnd();
          i0.ɵɵelement(3, "fly-data", 2);
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate1(" ", ctx.label, " ");
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.tooltipOptions);
          i0.ɵɵadvance();
          i0.ɵɵproperty("body", ctx.body)("unit", ctx.unit)("unit2", ctx.unit2);
        }
      },
      dependencies: [i1.NgIf, i2.IconComponent, i3.TooltipDirective, FlyDataComponent],
      styles: [".label[_ngcontent-%COMP%]{font-family:roboto;font-weight:400;font-size:.75rem;line-height:1.25rem}.label[_ngcontent-%COMP%]{display:flex;align-items:center;color:var(--text-secondary);gap:.25rem;height:1.125rem}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyDataVerticalComponent, [{
    type: Component,
    args: [{
      selector: 'fly-data-vertical',
      template: "<div class=\"label\">\n  {{label}}\n  <fly-icon [icon]=\"Icons.Tooltip\"\n            flyTooltip\n            *ngIf=\"tooltipOptions\"\n            [popOutOptions]=\"tooltipOptions\">\n  </fly-icon>\n</div>\n<fly-data [body]=\"body\"\n          [unit]=\"unit\"\n          [unit2]=\"unit2\">\n</fly-data>\n",
      styles: [".label{font-family:roboto;font-weight:400;font-size:.75rem;line-height:1.25rem}.label{display:flex;align-items:center;color:var(--text-secondary);gap:.25rem;height:1.125rem}\n"]
    }]
  }], null, {
    body: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    tooltipOptions: [{
      type: Input
    }],
    unit: [{
      type: Input
    }],
    unit2: [{
      type: Input
    }]
  });
})();
class FlyListHeaderComponent {
  constructor() {
    this.selected = false;
  }
  static {
    this.ɵfac = function FlyListHeaderComponent_Factory(t) {
      return new (t || FlyListHeaderComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FlyListHeaderComponent,
      selectors: [["fly-list-header"]],
      inputs: {
        text: "text",
        selected: "selected"
      },
      ngContentSelectors: _c0,
      decls: 4,
      vars: 3,
      consts: [[1, "wrapper", "overline"], [1, "text"]],
      template: function FlyListHeaderComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 0)(1, "div", 1);
          i0.ɵɵtext(2);
          i0.ɵɵelementEnd();
          i0.ɵɵprojection(3);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassProp("overline-semibold", ctx.selected);
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate(ctx.text);
        }
      },
      styles: ["[_nghost-%COMP%]{width:100%}.wrapper[_ngcontent-%COMP%]{display:flex;justify-content:space-between;padding:.5rem 1rem 0}.wrapper[_ngcontent-%COMP%] > *[_ngcontent-%COMP%]{width:fit-content}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyListHeaderComponent, [{
    type: Component,
    args: [{
      selector: 'fly-list-header',
      template: "<div class=\"wrapper overline\"\n     [class.overline-semibold]=\"selected\">\n  <div class=\"text\">{{text}}</div>\n  <ng-content></ng-content>\n</div>\n",
      styles: [":host{width:100%}.wrapper{display:flex;justify-content:space-between;padding:.5rem 1rem 0}.wrapper>*{width:fit-content}\n"]
    }]
  }], null, {
    text: [{
      type: Input
    }],
    selected: [{
      type: Input
    }]
  });
})();
class FlyListRowComponent {
  constructor() {
    this.title = 'Title';
    this.subtitle = 'Subtitle';
    this.subtitle2 = 'Subtitle';
    this.Icons = Icons;
  }
  static {
    this.ɵfac = function FlyListRowComponent_Factory(t) {
      return new (t || FlyListRowComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FlyListRowComponent,
      selectors: [["fly-list-row"]],
      inputs: {
        title: "title",
        tooltipOptions: "tooltipOptions",
        subtitle: "subtitle",
        subtitle2: "subtitle2"
      },
      ngContentSelectors: _c0,
      decls: 13,
      vars: 5,
      consts: [["flyButton", "", 1, "icon-button"], [3, "icon"], [1, "title"], [1, "subtext"]],
      template: function FlyListRowComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div")(1, "div")(2, "button", 0);
          i0.ɵɵelement(3, "fly-icon", 1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(4, "div", 2);
          i0.ɵɵtext(5);
          i0.ɵɵelementEnd();
          i0.ɵɵelement(6, "fly-icon", 1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(7, "div", 3);
          i0.ɵɵtext(8);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(9, "div", 3);
          i0.ɵɵtext(10);
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(11, "div");
          i0.ɵɵprojection(12);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("icon", ctx.Icons.Fuel);
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate(ctx.title);
          i0.ɵɵadvance();
          i0.ɵɵproperty("icon", ctx.Icons.Tooltip);
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate(ctx.subtitle);
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate(ctx.subtitle2);
        }
      },
      dependencies: [i1$1.FlyButtonDirective, i2.IconComponent],
      styles: [".title[_ngcontent-%COMP%]{font-family:roboto;font-weight:400;font-size:.875rem;line-height:1.313rem}.subtext[_ngcontent-%COMP%]{font-family:roboto;font-weight:400;font-size:.625rem;line-height:1rem}[_nghost-%COMP%]{display:flex;width:100%;padding:.25rem 1rem;border-bottom:.0625rem solid var(--ui-data-entry);justify-content:space-between;align-items:center}[_nghost-%COMP%] > [_ngcontent-%COMP%]:first-child > [_ngcontent-%COMP%]:first-child{display:flex;gap:.25rem;align-items:center}.icon-button[_ngcontent-%COMP%]{height:1.75rem;width:1.75rem}.title[_ngcontent-%COMP%]{color:var(--text-secondary)}.subtext[_ngcontent-%COMP%]{height:fit-content;color:var(--text-secondary)}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyListRowComponent, [{
    type: Component,
    args: [{
      selector: 'fly-list-row',
      template: "<div>\n  <div>\n    <button class=\"icon-button\"\n            flyButton>\n      <fly-icon [icon]=\"Icons.Fuel\">\n      </fly-icon>\n    </button>\n    <div class=\"title\">{{title}}</div>\n    <fly-icon\n              [icon]=\"Icons.Tooltip\">\n    </fly-icon>\n  </div>\n  <div class=\"subtext\">{{subtitle}}</div>\n  <div class=\"subtext\">{{subtitle2}}</div>\n</div>\n<div>\n  <ng-content></ng-content>\n</div>\n",
      styles: [".title{font-family:roboto;font-weight:400;font-size:.875rem;line-height:1.313rem}.subtext{font-family:roboto;font-weight:400;font-size:.625rem;line-height:1rem}:host{display:flex;width:100%;padding:.25rem 1rem;border-bottom:.0625rem solid var(--ui-data-entry);justify-content:space-between;align-items:center}:host>:first-child>:first-child{display:flex;gap:.25rem;align-items:center}.icon-button{height:1.75rem;width:1.75rem}.title{color:var(--text-secondary)}.subtext{height:fit-content;color:var(--text-secondary)}\n"]
    }]
  }], null, {
    title: [{
      type: Input
    }],
    tooltipOptions: [{
      type: Input
    }],
    subtitle: [{
      type: Input
    }],
    subtitle2: [{
      type: Input
    }]
  });
})();
var ListType;
(function (ListType) {
  ListType[ListType["DEFAULT"] = 0] = "DEFAULT";
  ListType[ListType["NEW"] = 1] = "NEW";
  ListType[ListType["IMPROVED"] = 2] = "IMPROVED";
  ListType[ListType["FIXED"] = 3] = "FIXED";
})(ListType || (ListType = {}));
class FlyUnorderedListComponent {
  constructor() {
    this.type = ListType.DEFAULT;
    this.ListType = ListType;
  }
  static {
    this.ɵfac = function FlyUnorderedListComponent_Factory(t) {
      return new (t || FlyUnorderedListComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FlyUnorderedListComponent,
      selectors: [["fly-ul"]],
      inputs: {
        title: "title",
        type: "type"
      },
      ngContentSelectors: _c0,
      decls: 4,
      vars: 7,
      template: function FlyUnorderedListComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div");
          i0.ɵɵtext(1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(2, "ul");
          i0.ɵɵprojection(3);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassProp("new", ctx.type === ctx.ListType.NEW)("improved", ctx.type === ctx.ListType.IMPROVED)("fixed", ctx.type === ctx.ListType.FIXED);
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate1(" ", ctx.title, "\n");
        }
      },
      styles: ["[_nghost-%COMP%]     li{font-family:roboto;font-weight:400;font-size:.75rem;line-height:1.25rem}ul[_ngcontent-%COMP%]{list-style-position:inside;margin-left:.5rem}.new[_ngcontent-%COMP%]{color:var(--success)}.improved[_ngcontent-%COMP%]{color:var(--link-primary)}.fixed[_ngcontent-%COMP%]{color:var(--text-caution)}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyUnorderedListComponent, [{
    type: Component,
    args: [{
      selector: 'fly-ul',
      template: "<div [class.new]=\"type === ListType.NEW\"\n     [class.improved]=\"type === ListType.IMPROVED\"\n     [class.fixed]=\"type === ListType.FIXED\">\n     {{title}}\n</div>\n<ul>\n  <ng-content></ng-content>\n</ul>\n",
      styles: [":host ::ng-deep li{font-family:roboto;font-weight:400;font-size:.75rem;line-height:1.25rem}ul{list-style-position:inside;margin-left:.5rem}.new{color:var(--success)}.improved{color:var(--link-primary)}.fixed{color:var(--text-caution)}\n"]
    }]
  }], null, {
    title: [{
      type: Input
    }],
    type: [{
      type: Input
    }]
  });
})();
var WxType;
(function (WxType) {
  WxType["VFR"] = "VFR";
  WxType["MVFR"] = "MVFR";
  WxType["IFR"] = "IFR";
  WxType["LIFR"] = "LIFR";
})(WxType || (WxType = {}));
class FlyWxConditionComponent {
  constructor() {
    this.WxType = WxType;
  }
  static {
    this.ɵfac = function FlyWxConditionComponent_Factory(t) {
      return new (t || FlyWxConditionComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FlyWxConditionComponent,
      selectors: [["fly-wx-condition"]],
      inputs: {
        type: "type"
      },
      decls: 3,
      vars: 9,
      consts: [[1, "ellipse", "vfr"]],
      template: function FlyWxConditionComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", 0);
          i0.ɵɵelementStart(1, "div");
          i0.ɵɵtext(2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassProp("vfr", ctx.type === ctx.WxType.VFR)("mvfr", ctx.type === ctx.WxType.MVFR)("ifr", ctx.type === ctx.WxType.IFR)("lifr", ctx.type === ctx.WxType.LIFR);
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate(ctx.type);
        }
      },
      styles: ["[_nghost-%COMP%]{display:flex;gap:.25rem;align-items:center}.ellipse[_ngcontent-%COMP%]{height:.875rem;width:.875rem;border-radius:50%}.vfr[_ngcontent-%COMP%]{background-color:#00720b}.mvfr[_ngcontent-%COMP%]{background-color:#0062ef}.ifr[_ngcontent-%COMP%]{background-color:#cf0c1f}.lifr[_ngcontent-%COMP%]{background-color:#f870f5}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyWxConditionComponent, [{
    type: Component,
    args: [{
      selector: 'fly-wx-condition',
      template: "<div class=\"ellipse vfr\"\n     [class.vfr]=\"type === WxType.VFR\"\n     [class.mvfr]=\"type === WxType.MVFR\"\n     [class.ifr]=\"type === WxType.IFR\"\n     [class.lifr]=\"type === WxType.LIFR\"></div>\n<div>{{type}}</div>\n",
      styles: [":host{display:flex;gap:.25rem;align-items:center}.ellipse{height:.875rem;width:.875rem;border-radius:50%}.vfr{background-color:#00720b}.mvfr{background-color:#0062ef}.ifr{background-color:#cf0c1f}.lifr{background-color:#f870f5}\n"]
    }]
  }], null, {
    type: [{
      type: Input
    }]
  });
})();
class FlyListDataModule {
  static {
    this.ɵfac = function FlyListDataModule_Factory(t) {
      return new (t || FlyListDataModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: FlyListDataModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, FlyButtonModule, FlyIconModule, FlyTooltipModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyListDataModule, [{
    type: NgModule,
    args: [{
      declarations: [FlyDataComponent, FlyDataHorizontalComponent, FlyDataVerticalComponent, FlyListHeaderComponent, FlyListRowComponent, FlyUnorderedListComponent, FlyWxConditionComponent],
      imports: [CommonModule, FlyButtonModule, FlyIconModule, FlyTooltipModule],
      exports: [FlyDataComponent, FlyDataHorizontalComponent, FlyDataVerticalComponent, FlyListHeaderComponent, FlyListRowComponent, FlyUnorderedListComponent, FlyWxConditionComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FlyDataComponent, FlyDataHorizontalComponent, FlyDataVerticalComponent, FlyListDataModule, FlyListHeaderComponent, FlyListRowComponent, FlyUnorderedListComponent, FlyWxConditionComponent, ListType, WxType };
